<template>
    <div>
        Qr view
    </div>
</template>

<script>
    export default {
        data() {
            return {
                key: "value"
            }
        },
        created(){
            var array = this.$route.query.placas.split(",")
            console.log(array)
        }
    }
</script>

<style lang="scss" scoped>

</style>